import "./layout.scss"
import {Header} from "../components";

export type PublicPageLayoutProps = {
  children: React.ReactNode;
};

export const PublicPageLayout = (props: PublicPageLayoutProps) => {

  return (
    <div>
      <div className='app-frame'>
        <div className='app-header'>
          <Header />
        </div>
        <div className='app-content'>
          {props.children}
        </div>
      </div>
    </div>
  );
};
