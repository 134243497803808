import axios from 'axios';
import {useEffect} from 'react';

const httpClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

const AxiosInstance = ({ children }: any) => {

  useEffect(() => {
    const requestInterceptor = async (config: any) => {
      if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
      }
      else {
        config.headers['Content-Type'] = 'application/json';
      }

      return config;
    };

    const req = httpClient.interceptors.request.use(
      requestInterceptor,
      (error) => {
        console.log('interceptor req error', error);
      }
    );

    return () => {
      httpClient.interceptors.request.eject(req);
    };
  }, [httpClient]);

  return children;
}

export default httpClient;
export { AxiosInstance };
