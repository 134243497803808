import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export function Section3() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={'section3'}>
      <div className={'headline title'}>{t('section3.title')}</div>
      <div className={'subtitle'}>{t('section3.subsection1.title')}</div>
      <div className={'content'}>{t('section3.subsection1.content1')}</div>
      <div className={'content'}>{t('section3.subsection1.content2')}</div>
      <div className={'subtitle'}>{t('section3.subsection2.title')}</div>
      <div className={'content'}>{t('section3.subsection2.content1')}</div>
      <div className={'content'}>{t('section3.subsection2.content2')}</div>
      <div className={'subcontent'}>
        <div className={'half-block half-block-left'}>
          <div className={'subtitle'}>{t('section3.subsection3.title')}</div>
          <div className={'content'}>{t('section3.subsection3.content')}</div>
        </div>
        <div className={'half-block'}>
          <div className={'subtitle'}>{t('section3.subsection4.title')}</div>
          <div className={'content'}>{t('section3.subsection4.content')}</div>
        </div>
      </div>
      <div style={{textAlign: 'center'}}>
        <Button style={{backgroundColor: '#ffba6e', marginTop: '12px', position:'unset'}} variant="contained"
                onClick={() => navigate('/keepintouch')}>
          {t('ribbon.keepintouch')}
        </Button>
      </div>
    </div>
  );
}
