import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import Button from "@mui/material/Button";
import {Grid, Hidden} from "@mui/material";

export function Section5() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={'section5'}>
      <div className={'headline title'}>{t('section5.title')}</div>
      <div className={'promo'}>
        <Grid container spacing={3}>
          <Grid item sm={12} md={9}>
            <div>
              <div className={'promoTitle'}>{t('section5.promo.title')}</div>
              <div className={'promoContent'}>{t('section5.promo.line1')}</div>
              <div className={'promoContent'}>{t('section5.promo.line2')}</div>
              <div className={'promoContent'}>{t('section5.promo.line3')}</div>
              <div className={'promoContent'}>{t('section5.promo.line4')}</div>
            </div>
          </Grid>
          <Hidden mdDown={true}>
            <Grid item sm={12} md={3}>
                <div className={'card promocard'}>
                  <div>
                    <div className={'cardtitle'}>{t('section5.cards.tiers3.title')}</div>
                    <div className={'cardneeds'}>{t('section5.cards.tiers3.needs')}</div>
                    <div className={'cardpromoprice barred'}>{t('section5.cards.tiers3.price')} {t('section5.cards.priceunit')}</div>
                    <div className={'cardprice'}>{t('section5.cards.tiers3.promoprice')} <span className={'currency'}>{t('section5.cards.priceunit')}</span></div>
                  </div>
                  <div>
                    <div className={'onboarding barred'}>{t('section5.cards.accompaniment')}</div>
                    <div className={'onboardingfees barred'}>{t('section5.cards.accompanimentprice')}</div>
                    <div className={'payment'}>{t('section5.cards.accompanimentpayment')}</div>
                  </div>
                </div>
            </Grid>
          </Hidden>
        </Grid>
      </div>
      <div className={'pricing'}>
        <Grid container spacing={3}>
          <Hidden mdDown={true}>
            <Grid item md={3}>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={4} md={3} sx={{width:'100%'}}>
            <div className={'card'}>
              <div>
                <div className={'cardtitle'}>{t('section5.cards.tiers1.title')}</div>
                <div className={'cardneeds'}>{t('section5.cards.tiers1.needs')}</div>
                <div className={'cardprice'}>{t('section5.cards.tiers1.price')} <span className={'currency'}>{t('section5.cards.priceunit')}</span></div>
              </div>
              <div>
                <div className={'onboarding'}>{t('section5.cards.accompaniment')}</div>
                <div className={'onboardingfees'}>{t('section5.cards.accompanimentprice')}</div>
                <div className={'payment'}>{t('section5.cards.accompanimentpayment')}</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={3} sx={{width: '100%'}}>
            <div className={'card'}>
              <div>
                <div className={'cardtitle'}>{t('section5.cards.tiers2.title')}</div>
                <div className={'cardneeds'}>{t('section5.cards.tiers2.needs')}</div>
                <div className={'cardprice'}>{t('section5.cards.tiers2.price')} <span className={'currency'}>{t('section5.cards.priceunit')}</span></div>
              </div>
              <div>
                <div className={'onboarding'}>{t('section5.cards.accompaniment')}</div>
                <div className={'onboardingfees'}>{t('section5.cards.accompanimentprice')}</div>
                <div className={'payment'}>{t('section5.cards.accompanimentpayment')}</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={3} sx={{width: '100%'}}>
            <div className={'card promocard'}>
              <div>
                <div className={'cardtitle'}>{t('section5.cards.tiers3.title')}</div>
                <div className={'cardneeds'}>{t('section5.cards.tiers3.needs')}</div>
                <div className={'cardpromoprice barred'}>{t('section5.cards.tiers3.price')} {t('section5.cards.priceunit')}</div>
                <div className={'cardprice'}>{t('section5.cards.tiers3.promoprice')} <span className={'currency'}>{t('section5.cards.priceunit')}</span></div>
              </div>
              <div>
                <div className={'onboarding barred'}>{t('section5.cards.accompaniment')}</div>
                <div className={'onboardingfees barred'}>{t('section5.cards.accompanimentprice')}</div>
                <div className={'payment'}>{t('section5.cards.accompanimentpayment')}</div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={'features'}>
        <Grid container columnSpacing={3} rowSpacing={2}>
          <Hidden mdUp={true}>
            <Grid item xs={12}>
              <div className={'featureseparator'}/>
            </Grid>
            <Grid item xs={3}>
              <div className={'feature featuretitle'}></div>
            </Grid>
            <Grid item xs={3}>
              <div className={'feature featureheader'}>{t('section5.cards.tiers1.title')}</div>
            </Grid>
            <Grid item xs={3}>
              <div className={'feature featureheader'}>{t('section5.cards.tiers2.title')}</div>
            </Grid>
            <Grid item xs={3}>
              <div className={'feature featureheader'}>{t('section5.cards.tiers3.title')}</div>
            </Grid>
          </Hidden>
          <Grid item xs={12}>
            <Grid item xs={1}/>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature1')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>1</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>5</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>{t('section5.featuresvalues.unlimited')}</div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature2')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature3')}</div>
          </Grid>

          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature4')}</div>
          </Grid>

          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature5')}</div>
          </Grid>

          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature6')}</div>
          </Grid>

          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature7')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature8')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature9')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/excluded.svg'} className={'inclusion'}
                   alt={'Excluded'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature10')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/excluded.svg'} className={'inclusion'}
                   alt={'Excluded'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature11')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/excluded.svg'} className={'inclusion'}
                   alt={'Excluded'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/excluded.svg'} className={'inclusion'}
                   alt={'Excluded'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature12')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>
              <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/included.svg'} className={'inclusion'}
                   alt={'Included'}/>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature13')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>5</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>15</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>{t('section5.featuresvalues.unlimited')}</div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature14')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>50Go</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>200Go</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>{t('section5.featuresvalues.unlimited')}</div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature15')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>3 {t('section5.featuresvalues.months')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>6 {t('section5.featuresvalues.months')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>{t('section5.featuresvalues.unlimited')}</div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature featuretitle'}>{t('section5.features.feature16')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>{t('section5.featuresvalues.standard')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>{t('section5.featuresvalues.accompaniment')}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={'feature'}>{t('section5.featuresvalues.expert')}</div>
          </Grid>
          <Grid item xs={12}>
            <div className={'featureseparator'}/>
          </Grid>
        </Grid>
      </div>
      <div className={'fees'}>
        <div className={'feesTitle'}>{t('section5.accompaniment')}</div>
        <div className={'feesContent'}>{t('section5.accompanimentdescription')}</div>
      </div>
      <div style={{textAlign: 'center'}}>
        <Button style={{backgroundColor: '#ffba6e', marginTop: '12px', position:'unset'}} variant="contained"
                onClick={() => navigate('/keepintouch')}>
          {t('ribbon.keepintouch')}
        </Button>
      </div>
    </div>
  );
}
