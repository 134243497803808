import classNames from 'classnames';
import './debug-banner.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function DebugBanner() {
  const isLocal = process.env.REACT_APP_DEVOPS_ENV === 'local';
  const isDev = process.env.REACT_APP_DEVOPS_ENV === 'dev';
  const isQa = process.env.REACT_APP_DEVOPS_ENV === 'qa';
  const isUat = process.env.REACT_APP_DEVOPS_ENV === 'uat';
  const isStaging = process.env.REACT_APP_DEVOPS_ENV === 'staging';
  const isWeb = process.env.REACT_APP_DEVOPS_ENV === 'web';

  return isLocal || isDev || isQa || isUat || isStaging || isWeb ? (
    <div className={'debug-banner-container'}>
      <div
        className={classNames('debug-banner', {
          'debug-banner-local': isLocal,
          'debug-banner-dev': isDev,
          'debug-banner-qa': isQa,
          'debug-banner-uat': isUat,
          'debug-banner-staging': isStaging,
          'debug-banner-web': isWeb
        })}>{process.env.REACT_APP_DEVOPS_ENV?.toUpperCase()}</div>
    </div>
  ) : null;
}
