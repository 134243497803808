import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={'ribbon'}>
      <div className={'ribbon-block'}>
        <div className={'ribbon-logo'}>
          <img src='/logo.png' alt='logo' className={'logo'} />
          <div className={'name'}>Jabs</div>
        </div>
        {/*<Button variant="text" onClick={() => navigate('/solutions')}>*/}
        {/*  {t('ribbon.solutions')}*/}
        {/*</Button>*/}
        {/*<Button variant="text" onClick={() => navigate('/pricing')}>*/}
        {/*  {t('ribbon.pricing')}*/}
        {/*</Button>*/}
        {/*<Button variant="text" onClick={() => navigate('/about')}>*/}
        {/*  {t('ribbon.about')}*/}
        {/*</Button>*/}
      </div>
      <div className={'ribbon-block ribbon-block-right'}>
        {/*<Button variant="text" onClick={() => navigate('/connection')}>*/}
        {/*  {t('ribbon.connection')}*/}
        {/*</Button>*/}
        {/*{*/}
        {/*  props.showKeepInTouch === undefined || props.showKeepInTouch ?*/}
        {/*    <Button style={{backgroundColor:'#ffba6e'}} variant="contained" onClick={() => navigate('/keepintouch')}>*/}
        {/*      {t('ribbon.keepintouch')}*/}
        {/*    </Button>*/}
        {/*  : <></>*/}
        {/*}*/}
      </div>
    </div>
  );
}
