import {Footer} from "../../components";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {TextField} from "@mui/material";
import {useState} from "react";
import classNames from 'classnames';
import httpClient from "../../api/AxiosInstance";


export function KeepInTouch() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: '',
    name: '',
    companyName: '',
    role: ''
  });
  const [errors, setErrors] = useState({
    email: false,
    name: false
  });
  const [sent, setSent] = useState(false);


  const validateEmail = (value: string) => {
    let currentEmails = value
      .split(',')
      .filter((e) => e && e.trim());
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    for (let i = 0; i < currentEmails.length; i++) {
      if (!regex.test(currentEmails[i].replace(/\s/g, ''))) {
        return false;
      }
    }
    return true;
  }

  const validate = () => {

    let hasErrors = false;
    if (form.email === undefined || form.email === '' || !validateEmail(form.email)) {
      hasErrors = true;
      setErrors(old => ({...old, email: true}));
    }

    if (form.name === undefined || form.name === '') {
      hasErrors = true;
      setErrors(old => ({...old, name: true}));
    }

    if (!hasErrors)
    {
      console.warn('Submit!!!');
      httpClient
        .post('/api/v1/keepintouch', JSON.stringify(form))
        .then((result) => {
          console.warn('Done');
          console.warn(result);
        })
        .finally(() => {
          setForm({ email: '', name: '', companyName: '', role: '' });
          setSent(true);
        });
    }
  }

  const formClass = classNames('keepintouchform', { 'hidden': sent });
  const validationClass = classNames('keepintouchform', { 'hidden': !sent });

  return (
    <>
      <div className={'keepintouchsection'}>
        <div className={'half-block-left'}>
          <img src={'/images/delivery.jpg'} className={'headimg'} alt={'Delivery'}/>
        </div>
        <div className={'half-block-right'}>
          <div id={'confirm'} className={validationClass}>
            <div className={'header'}>
              {t('keepintouch.header')}
            </div>
            <div className={'headline'}>
              {t('keepintouch.confirm.thanks')}
            </div>
            <Button
              sx={{width: '100%'}}
              variant="contained"
              onClick={() => navigate('/')}>
              {t('keepintouch.confirm.back')}
            </Button>
          </div>
          <div id={'form'} className={formClass}>
            <div className={'header'}>
              {t('keepintouch.header')}
            </div>
            <div className={'headline'}>
              {t('keepintouch.form.headline')}
            </div>
            <TextField
              required
              label={t('keepintouch.form.name')}
              variant="outlined"
              error={errors.name}
              size="small"
              margin="dense"
              sx={{width: '100%'}}
              InputLabelProps={{shrink: true}}
              value={form.name}
              onChange={(e) => {
                setForm(old => ({...old, name: e.target.value}));
                setErrors(old => ({...old, name: false}));
              }}
            />
            <TextField
              required
              label={t('keepintouch.form.email')}
              variant="outlined"
              error={errors.email}
              size="small"
              margin="dense"
              sx={{width: '100%'}}
              InputLabelProps={{shrink: true}}
              value={form.email}
              onChange={(e) => {
                setForm(old => ({...old, email: e.target.value}));
                setErrors(old => ({...old, email: false}));
              }}
            />
            <TextField
              label={t('keepintouch.form.companyName')}
              variant="outlined"
              size="small"
              margin="dense"
              sx={{width: '100%'}}
              InputLabelProps={{shrink: true}}
              value={form.companyName}
              onChange={(e) => {
                setForm(old => ({...old, companyName: e.target.value}));
              }}
            />
            <TextField
              label={t('keepintouch.form.role')}
              variant="outlined"
              size="small"
              margin="dense"
              sx={{width: '100%'}}
              InputLabelProps={{shrink: true}}
              value={form.role}
              onChange={(e) => {
                setForm(old => ({...old, role: e.target.value}));
              }}
            />
            <Button
              sx={{width: '100%'}}
              variant="contained"
              onClick={validate}>
              {t('keepintouch.form.submit')}
            </Button>
            <Button
              sx={{width: '100%', marginTop:'4px', marginBottom:'-24px'}}
              variant="text"
              onClick={() => navigate('/')}>
              {t('keepintouch.confirm.back')}
            </Button>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}
