import { Routes, Route } from 'react-router-dom';
import {
  KeepInTouch,
  Landing,
  NotFound,
} from './pages';
import {PublicPageLayout} from './layout';

export default function AppRoutes() {

  return (
      <Routes>
        <Route
          path="/"
          element={
            <PublicPageLayout>
              <Landing />
            </PublicPageLayout>} />
        <Route
          path="/keepintouch"
          element={
            <PublicPageLayout>
              <KeepInTouch />
            </PublicPageLayout>} />
        <Route
          path="*"
          element={<NotFound />} />
      </Routes>
  );
}
