import {Footer, Section1, Section2, Section3, Section4, Section5} from "../../components";

export function Landing() {
  return (
    <div style={{marginTop:'100px', display:'flex', flexDirection:'column'}}>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section5 />
      <Footer />
    </div>
  );
}
