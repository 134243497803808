import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import './footer.scss';

export function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {/*<div className={'before-footer'} />*/}
      <div className={'footer'}>
        <div className={'quater-block-1'}>
        </div>
        <div className={'quater-block-2'}>
          {/*<div className={'footer-header'}>{t('footer.ourproducts')}</div>*/}
          {/*<a href={'/test'} className={'footer-link'}>{t('footer.pim')}</a>*/}
          {/*<a href={'/test'} className={'footer-link'}>{t('footer.customerstore')}</a>*/}
          {/*<a href={'/test'} className={'footer-link'}>{t('footer.ai')}</a>*/}
          {/*<a href={'/test'} className={'footer-link'}>{t('footer.faq')}</a>*/}
          {/*<a href={'/test'} className={'footer-link'}>{t('footer.pricing')}</a>*/}
        </div>
        <div className={'quater-block-2'}>
          {/*<div className={'footer-header'}>{t('footer.ourcompany')}</div>*/}
          {/*<a href={'/test'} className={'footer-link'}>{t('footer.about')}</a>*/}
          {/*<a href={'/test'} className={'footer-link'}>{t('footer.contactus')}</a>*/}
          {/*<a href={'/test'} className={'footer-link'}>{t('footer.career')}</a>*/}
          {/*<a href={'/test'} className={'footer-link'}>{t('footer.partnership')}</a>*/}
        </div>
        <div className={'quater-block-2'}>
          <div className={'contact'}>{t('footer.contact')}</div>
          <a className={'email'} href={'mailto:contact@jabs-solutions.com'}>contact@jabs-solutions.com</a>
          {/*<div className={'footer-header'}>{t('footer.resources')}</div>*/}
          {/*<a href={'/test'} className={'footer-link'}>{t('footer.helpcenter')}</a>*/}
          {/*<a href={'/test'} className={'footer-link'}>{t('footer.apidoc')}</a>*/}
        </div>
      </div>
    </>
  );
}
