import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export function Section1() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={'section1'}>
      <div className={'half-block-left'}>
        <h2 className={'headline'}>{t('landing.headline')}</h2>
        <h3 className={'content'}>{t('landing.content')}</h3>
        <div className={'tags'}>
          <div className={'tag'}>{t('landing.tag1')}</div>
          <div className={'tag'}>{t('landing.tag2')}</div>
          <div className={'tag'}>{t('landing.tag3')}</div>
        </div>
        <div className={'tags'}>
          <div className={'tag'}>{t('landing.tag4')}</div>
          <div className={'tag'}>{t('landing.tag5')}</div>
        </div>
        <Button style={{backgroundColor: '#ffba6e', marginTop: '12px', position:'unset'}} variant="contained"
                onClick={() => navigate('/keepintouch')}>
          {t('ribbon.keepintouch')}
        </Button>
      </div>
      <div className={'half-block-right'}>
        <img src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/delivery.jpg'} className={'headimg'} alt={'Delivery'}/>
      </div>
    </div>
  );
}
