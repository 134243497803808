import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export function Section2() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={'section2'}>
      <div className={'headline title'}>{t('section2.title')}</div>
      <div className={'tags'}>
        <div className={'tag'}>{t('section2.subsection1.tag')}</div>
      </div>
      <div className={'subtitle'}>{t('section2.subsection1.subtitle')}</div>
      <div className={'subcontent'}>
        <div className={'half-block'}>
          <img className={'valueimg'} src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/value1.svg'} alt={'value1'}/>
          <div>
            <div className={'valuetitle'}>{t('section2.subsection1.value1title')}</div>
            <div>{t('section2.subsection1.value1text')}</div>
          </div>
        </div>
        <div className={'half-block'}>
          <img className={'valueimg'} src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/value2.svg'} alt={'value1'}/>
          <div>
            <div className={'valuetitle'}>{t('section2.subsection1.value2title')}</div>
            <div>{t('section2.subsection1.value2text')}</div>
          </div>
        </div>
      </div>
      <div className={'separator'}/>
      <div className={'tags'}>
        <div className={'tag'}>{t('section2.subsection2.tag')}</div>
      </div>
      <div className={'subtitle'}>{t('section2.subsection2.subtitle')}</div>
      <div className={'subcontent'}>
        <div className={'half-block'}>
          <img className={'valueimg'} src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/value3.svg'} alt={'value1'}/>
          <div>
            <div className={'valuetitle'}>{t('section2.subsection2.value1title')}</div>
            <div>{t('section2.subsection2.value1text')}</div>
          </div>
        </div>
        <div className={'half-block'}>
        </div>
      </div>
      <div className={'separator'}/>
      <div className={'tags'}>
        <div className={'tag'}>{t('section2.subsection3.tag')}</div>
      </div>
      <div className={'subtitle'}>{t('section2.subsection3.subtitle')}</div>
      <div className={'subcontent'}>
        <div className={'half-block'}>
          <img className={'valueimg'} src={(process.env.REACT_APP_CDN_DOMAIN ?? '') + '/images/value4.svg'} alt={'value1'}/>
          <div>
            <div className={'valuetitle'}>{t('section2.subsection3.value1title')}</div>
            <div>{t('section2.subsection3.value1text')}</div>
          </div>
        </div>
        <div className={'half-block'}>
        </div>
      </div>
      <div className={'separator'} />
      <div style={{textAlign:'center'}}>
        <Button style={{backgroundColor: '#ffba6e', marginTop: '12px', position:'unset'}} variant="contained"
                onClick={() => navigate('/keepintouch')}>
          {t('ribbon.keepintouch')}
        </Button>
      </div>
    </div>
  );
}
